/**
* Generated automatically at built-time (2024-09-05T11:36:24.358Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";
export default ({screen, siteContext})=>{
const helmet = mergeHelmet(siteContext.helmet, screen.helmet);
const pageId = "custom-made-cantinetta-vino";
return {[pageId]: {id: pageId,helmet,className: getComponentClassName(pageId),items: getScreenTemplates(screen)}};
}